import React from 'react';
import './LoginForm.css';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Form from '../../components/LoginForm/Form';
import skiplistLogo from '../../images/skiplistLogo.png';

class LoginForm extends React.Component {
  state = {
    username: '',
    password: '',
  };

  handleSignIn(e) {
    e.preventDefault();
    let username = this.state.username;
    let password = this.state.password;
    this.props.onSignIn(username, password);
  }

  render() {
    return (
      <div className="login">
        <Form>
          <div className="form-container">
            <div className="logo-container">
              <img className="login-logo" src={skiplistLogo} alt="Logo" style={{ marginLeft: '15px' }} />
            </div>
            <div className="item-container">
              <Input
                type={'text'}
                placeholder={'Username'}
                value={this.state.username}
                onChange={value => {
                  this.setState({ username: value });
                }}
              />
            </div>
            <div className="item-container">
              <Input
                type={'password'}
                placeholder={'Password'}
                value={this.state.password}
                onChange={value => {
                  this.setState({ password: value });
                }}
              />
            </div>
            <div className="item-container">
              <Button onClick={this.handleSignIn.bind(this)} text={'Sign in'} />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default LoginForm;
