import React from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../../images/skiplistLogo.png';
import './Background.css';

class Background extends React.Component {
  render() {
    return (
      <div className="view-background">
        <img className="view-background-logo" src={logo} alt="logo" />
      </div>
    );
  }
}

export default withRouter(Background);
