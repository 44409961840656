import React from 'react';
import './ToolbarButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ToolbarButton extends React.Component {
  handleClick() {
    this.props.onClick(this.props.sidebarComponent);

    // console.log(this.props.title)
    // console.log(this.state.prev)
    // console.log('------------------------------')

    if (this.props.title !== 'Collapse') {
      this.props.onClickCollapse(this.props.title);
    }
  }

  render() {
    return (
      <button className="toolbar-button" onClick={this.handleClick.bind(this)}>
        <FontAwesomeIcon icon={this.props.icon} />
        {!this.props.isMini ? <p className="toolbar-button-title">{this.props.title}</p> : null}
      </button>
    );
  }
}

export default ToolbarButton;
