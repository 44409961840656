import React from 'react';
import LoginForm from './LoginForm';
import { connect } from 'react-redux';
import { login } from '../../modules/auth';
import { withRouter } from 'react-router';

import './LoginForm.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  async signIn(username, password) {
    const signin = await this.props.dispatch(login(username, password));
    if (signin.body && signin.body.token) {
      localStorage.setItem('jwt', signin.body.token);
      localStorage.setItem('user', JSON.stringify(signin.body.user));
      this.props.history.push('/dashboard');
    }
  }

  signOut() {
    this.setState({ user: null });
  }

  render() {
    return (
      <div className="login-page">
        <LoginForm onSignIn={this.signIn.bind(this)} />
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    null
  )(Login)
);
