/** @format */

import { project_survey } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createProjectSurvey: 'PROJECT_SURVEY_CREATE',
  updateProjectSurvey: 'PROJECT_SURVEY_UPDATE',
  listProjectSurvey: 'PROJECT_SURVEY_LIST',
  fetchProjectSurvey: 'PROJECT_SURVEY_FETCH',
  deleteProjectSurvey: 'PROJECT_SURVEY_DELETE',
};

export function deleteProjectSurvey(id) {
  return project_survey.delete(types.deleteProjectSurvey, id);
}

export function fetchProjectSurvey(id) {
  return project_survey.get(types.fetchProjectSurvey, id, []);
}

export function listProjectSurvey() {
  return project_survey.list(types.listProjectSurvey, []);
}

export function editProjectSurvey(id, projectSurvey) {
  return project_survey.update(types.updateProjectSurvey, id, projectSurvey, []);
}

export function createProjectSurvey(projectSurvey) {
  return project_survey.create(types.createProjectSurvey, projectSurvey, []);
}

const INITIAL_STATE = Map({
  project_survey: {},
  project_surveys: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.listProjectSurvey:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          project_surveys: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.updateProjectSurvey:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          project_surveys: actionSucceeds(action)
            ? state.get('project_surveys').map(project_survey => {
                if (project_survey._id === action.body._id) {
                  return action.body;
                } else {
                  return project_survey;
                }
              })
            : state.get('project_surveys'),
        })
      );
    case types.deleteProjectSurvey:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          project_surveys: actionSucceeds(action)
            ? state.get('project_surveys').filter(project_survey => {
                return project_survey._id !== action.body._id;
              })
            : state.get('project_surveys'),
        })
      );
    case types.createProjectSurvey:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          project_surveys: actionSucceeds(action)
            ? state.get('project_surveys').concat([action.body])
            : state.get('project_surveys'),
        })
      );
    default:
      return state;
  }
}
