/** @format */

export default function request(type, path, method, payload, query) {
  return async (dispatch, store) => {
    let token = '';
    if (localStorage.getItem('jwt')) {
      token = localStorage.getItem('jwt');
    }
    const hasBody = method !== 'GET';

    const queryString = query && query.map(param => `${Object.keys(param)[0]}=${Object.values(param)[0]}`).join('&');
    const suffix = query ? path + '?' + queryString : path;
    const url = `${process.env.REACT_APP_API_SERVER}${suffix}`;
    const body = hasBody ? JSON.stringify(payload) : undefined;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    };

    dispatch({
      type,
      url,
      payload,
      query,
      isLoading: true,
    });

    return window
      .fetch(url, {
        method,
        body,
        headers: new window.Headers(headers),
        mode: 'cors',
        cache: 'default',
        redirect: 'follow',
      })
      .then(res => {
        if (res.status === 401) {
          window.location = '/login';
        }
        return res.json().then(
          body => {
            if (res.status === 401) {
              window.location = '/login';
            }

            return dispatch({
              type,
              body,
              payload,
              query,
              url: res.url,
              status: res.status,
              headers: res.headers,
              ok: res.ok,
              isLoading: false,
            });
          },
          error => {
            return dispatch({
              type,
              error,
              payload,
              query,
              url: res.url,
              status: res.status,
              headers: res.headers,
              parseError: true,
              isLoading: false,
            });
          }
        );
      })
      .catch(error => {
        return dispatch({
          type,
          error,
          url,
          payload,
          query,
          failed: true,
          isLoading: false,
        });
      });
  };
}
