import React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { Redirect, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
// eslint-disable-next-line
import { Route } from 'react-router';
import './Table.css';
import Table from 'react-bootstrap/Table';
import Button from '../Button/Button';
import { deleteCompany, listCompany } from '../../modules/company';
import { deleteContact, listContact } from '../../modules/contact';
import { deleteProject, listProject } from '../../modules/project';
import { deleteUser, listUser } from '../../modules/user';
import { faPencilAlt, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import CompanyEditForm from './EditForms/CompanyEditForm';
import ContactEditForm from './EditForms/ContactEditForm';
import ProjectEditForm from './EditForms/ProjectEditForm';
import UserEditForm from './EditForms/UserEditForm';
// eslint-disable-next-line
import View from './ViewPages/View';
import { deleteProjectSurvey, listProjectSurvey } from '../../modules/project_survey';

let path = '';

export function findPath() {
  return path;
}

class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      view: false,
      viewPage: '',
      companyEditing: '',
      contactEditing: '',
      projectEditing: '',
      userEditing: '',
    };
  }

  handleView(id, name) {
    this.setState({
      id: id,
      name: name,
      view: !this.state.view,
    });
    path = name;

    this.props.history.push({
      pathname: `/` + this.props.type + `/view/${name}`,
      state: { id: id, type: this.props.type },
    });
  }

  handleEdit(id, index) {
    this.setState({
      ...this.state,
      id: id,
      editIndex: index,
      companyEditing: this.props.type === 'company' && !this.state.companyEditing,
      contactEditing: this.props.type === 'contact' && !this.state.contactEditing,
      projectEditing: this.props.type === 'project' && !this.state.projectEditing,
      userEditing: this.props.type === 'user' && !this.state.userEditing,
    });
  }

  async handleDelete(id) {
    try {
      if (this.props.type === 'company') {
        await this.props.dispatch(deleteCompany(id));
        this.props.dispatch(listCompany());
      } else if (this.props.type === 'contact') {
        await this.props.dispatch(deleteContact(id));
        this.props.dispatch(listContact());
      } else if (this.props.type === 'project') {
        await this.props.dispatch(deleteProject(id));
        this.props.dispatch(listProject());
      } else if (this.props.type === 'user') {
        await this.props.dispatch(deleteUser(id));
        this.props.dispatch(listUser());
      } else if (this.props.type === 'project_survey') {
        await this.props.dispatch(deleteProjectSurvey(id));
        this.props.dispatch(listProjectSurvey());
      }
    } catch (err) {
      console(err);
    }

    // window.location.reload();
  }

  render() {
    if (!this.state.view) {
      return (
        <div>
          <Table>
            <thead>
              <tr>
                {this.props.columnHeaders.map((header, index) => {
                  return <th key={index}>{header}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {this.props.data.length !== 0
                ? this.props.data.map((datum, index) => {
                    return (
                      <tr key={index}>
                        {this.props.columnHeaders.map((field, index2) => {
                          if (field === 'taken') {
                            return <td key={index2}>{datum[field] ? 'Yes' : 'No'}</td>;
                          } else if (field === 'contributors') {
                            return <td key={index2}>{datum[field].length}</td>;
                          } else if (
                            field !== 'primary_contact' &&
                            field !== 'data' &&
                            field !== 'accounting_contact' &&
                            field !== 'company'
                          ) {
                            return <td key={index2}>{datum[field]}</td>;
                          } else {
                            return <td key={index2}>{datum[field] ? datum[field].name : null}</td>;
                          }
                        })}
                        {this.props.type !== 'project_survey' && (
                          <Button
                            className="view-button"
                            onClick={() => {
                              this.handleView(datum['id'], datum['name'] ? datum['name'] : datum['id']);
                            }}
                            icon={faEye}
                          />
                        )}
                        {this.props.type !== 'project_survey' && (
                          <Button
                            className="edit-button"
                            onClick={() => {
                              this.handleEdit(datum['id'], index);
                            }}
                            icon={faPencilAlt}
                          />
                        )}

                        <Button
                          className="delete-button"
                          onClick={() => {
                            this.handleDelete(datum['id']);
                          }}
                          icon={faTrash}
                        />
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>

          {this.state.companyEditing ? (
            <CompanyEditForm id={this.state.id} data={this.props.data[this.state.editIndex]} />
          ) : (
            ''
          )}
          {this.state.contactEditing ? (
            <ContactEditForm id={this.state.id} data={this.props.data[this.state.editIndex]} />
          ) : (
            ''
          )}
          {this.state.projectEditing ? (
            <ProjectEditForm id={this.state.id} data={this.props.data[this.state.editIndex]} />
          ) : (
            ''
          )}
          {this.state.userEditing ? (
            <UserEditForm id={this.state.id} data={this.props.data[this.state.editIndex]} />
          ) : (
            ''
          )}
        </div>
      );
    }
  }
}
export default withRouter(
  connect(state => {
    return state;
  })(CustomTable)
);
