import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTimes, faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import './Task.css';

export default class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: [],
      editing: [],
      edit_task_description: '',
    };
  }

  ClickDelete(id) {
    this.props.delete(id);
  }

  ClickView(id) {
    let temp = this.state.clicked;

    //Already open
    if (temp.includes(id)) {
      temp.splice(temp.indexOf(id), 1);
    }
    //Not open
    else {
      temp.push(id);
    }

    this.setState({
      clicked: temp,
    });
  }

  ClickEdit(id) {
    let temp = this.state.editing;

    //Already open
    if (temp.includes(id)) {
      temp.splice(temp.indexOf(id), 1);
    }
    //Not open
    else {
      temp.push(id);
    }

    this.setState({
      editing: temp,
    });
  }

  onChangeEditNote(event) {
    this.setState({ edit_task_description: { ...this.state.edit_task_description, entry: event.target.value } });
  }

  ClickEditSubmit(id) {
    let temp = this.state.editing;
    temp.splice(temp.indexOf(id), 1);

    if (this.state.edit_task_description !== '') {
      this.props.edit(id, this.state.edit_task_description);
    }

    this.setState({
      editing: temp,
    });
  }

  render() {
    return (
      <div className="task-container">
        <Draggable draggableId={this.props.task.id} index={this.props.index}>
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
            >
              <div
                style={{
                  backgroundColor: snapshot.isDragging ? 'lightGreen' : 'transparent',
                  border: snapshot.isDragging ? '1px solid black' : null,
                  borderRadius: snapshot.isDragging ? '10px' : null,
                }}
              >
                <div className="task-containeer">
                  <h3 className="task-title">
                    {this.props.task.name}
                    <button className="task-delete-button" onClick={() => this.ClickDelete(this.props.task.id)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <button className="task-view-button" onClick={() => this.ClickView(this.props.task.id)}>
                      {this.state.clicked.includes(this.props.task.id) ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </button>
                  </h3>

                  {this.state.clicked.includes(this.props.task.id) &&
                  this.state.editing.includes(this.props.task.id) ? (
                    <div>
                      <textarea className="task-description-edit" onChange={this.onChangeEditNote.bind(this)}>
                        {this.props.task.description}
                      </textarea>
                      <button className="task-check-icon" onClick={() => this.ClickEditSubmit(this.props.task.id)}>
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                    </div>
                  ) : (
                    this.state.clicked.includes(this.props.task.id) && (
                      <span className="task-description">
                        {this.props.task.description}
                        <button className="task-pencil-icon" onClick={() => this.ClickEdit(this.props.task.id)}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </Draggable>
      </div>
    );
  }
}
