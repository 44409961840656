import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './CenterNav.css';
import Notes from './CenterNavPages/Notes';
import Files from './CenterNavPages/Files';
import Sprint from './CenterNavPages/Sprint';
import Surveys from './CenterNavPages/Surveys';

const highlight = {
  background: 'radial-gradient(circle, #00b7e08a 0%, hsl(0, 0%, 100%) 160%)',
  borderLeft: '1px solid black',
  borderRight: '1px solid black',
  borderTop: '1px solid black',
};

class CenterNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: this.props.logo,
      clicked: '',
      tabs: ['activity', 'files', 'notes', 'sprint', 'surveys'],
    };
  }

  handleTabClick(tabName) {
    this.setState({
      clicked: tabName,
    });
  }

  render() {
    return (
      <div className="center-panel">
        <div className="center-nav">
          <div className="nav-options">
            <ul>
              {this.state.tabs.map((name, index) => {
                let style = null;
                if (this.state.clicked === name) {
                  style = highlight;
                }
                if (name === 'sprint' && this.props.type !== 'project') {
                  return null;
                } else if (name === 'surveys' && this.props.type !== 'project') {
                  return null;
                } else {
                  return (
                    <li>
                      <button style={style} onClick={() => this.handleTabClick(name)}>
                        <span>{name.charAt(0).toUpperCase() + name.substring(1)}</span>
                      </button>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
        {/* <div className="search-panel">
          <div className="filter-search">
            <label className="filter-search">
              Filter By:
              <button>
                <span>Filter DROPDOWN</span>
              </button>
            </label>
          </div>
        </div> */}
        <div className="main-content">
          {this.state.clicked === '' && null}
          {this.state.clicked === 'activity' && null}
          {this.state.clicked === 'sprint' && this.props.type === 'project' ? (
            <Sprint object={this.props.object} />
          ) : null}
          {this.state.clicked === 'files' && <Files object={this.props.object} type={this.props.type} />}
          {this.state.clicked === 'notes' && <Notes object={this.props.object} type={this.props.type} />}
          {this.state.clicked === 'surveys' && <Surveys object={this.props.object} />}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(CenterNav)
);
