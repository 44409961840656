/** @format */

import { user } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createUser: 'USER_CREATE',
  updateUser: 'USER_UPDATE',
  listUser: 'USER_LIST',
  fetchUser: 'USER_FETCH',
  deleteUser: 'USER_DELETE',
};

export function deleteUser(id) {
  return user.delete(types.deleteUser, id);
}

export function fetchUser(id) {
  return user.get(types.fetchUser, id, []);
}

export function listUser() {
  return user.list(types.listUser, []);
}

export function editUser(id, use) {
  return user.update(types.updateUser, id, use, []);
}

export function createUser(use) {
  return user.create(types.createUser, use, []);
}

const INITIAL_STATE = Map({
  user: {},
  users: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.fetchUser:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          user: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.listUser:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          users: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.updateUser:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          users: actionSucceeds(action)
            ? state.get('users').map(user => {
                if (user._id === action.body._id) {
                  return action.body;
                } else {
                  return user;
                }
              })
            : state.get('users'),
        })
      );
    case types.deleteUser:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          users: actionSucceeds(action)
            ? state.get('users').filter(user => {
                return user._id !== action.body._id;
              })
            : state.get('users'),
        })
      );
    case types.createUser:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          users: actionSucceeds(action) ? state.get('users').concat([action.body]) : state.get('users'),
        })
      );
    default:
      return state;
  }
}
