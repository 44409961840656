import React from 'react';
import './SubNav.css';
import ActionButton from '../../ActionButton/ActionButton';

class SubNav extends React.Component {
  render() {
    return (
      <div className="subnav-toolbar">
        <h4 className="title">{this.props.title}</h4>
        {this.props.actions.map((action, index) => {
          return <ActionButton key={index} icon={action.icon} title={action.title} url={action.url} />;
        })}
      </div>
    );
  }
}

export default SubNav;
