import React from 'react';
import './FormColumn.css';

class FormColumn extends React.Component {
  render() {
    return (
      <div className="form-row">
        <h5 className="form-title">{this.props.title || 'Title'}</h5>
        {this.props.children}
      </div>
    );
  }
}

export default FormColumn;
