import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Table from '../../../components/Table/Table';

import { listCompany } from '../../../modules/company';
import './CompanyList.css';

class CompanyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(listCompany());
  }

  async onClick() {}

  signOut() {
    this.setState({ user: null });
  }

  render() {
    // console.log(this.props.company);
    const companies =
      this.props.company && this.props.company.get('companies') && this.props.company.get('companies').length > 0
        ? this.props.company.get('companies')
        : [];
    const headers = companies && companies.length > 0 ? Object.keys(companies[0]).slice(0, 8) : [];
    return (
      <div className="company-list">
        <div>
          <h4>Companies List</h4>
        </div>
        <Table columnHeaders={headers} data={companies} type="company" />
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(CompanyList)
);
