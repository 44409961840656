/** @format */

import { project } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createProject: 'PROJECT_CREATE',
  updateProject: 'PROJECT_UPDATE',
  listProject: 'PROJECT_LIST',
  fetchProject: 'PROJECT_FETCH',
  deleteProject: 'PROJECT_DELETE',
};

export function deleteProject(id) {
  return project.delete(types.deleteProject, id);
}

export function fetchProject(id) {
  return project.get(types.fetchProject, id, []);
}

export function listProject() {
  return project.list(types.listProject, []);
}

export function editProject(id, proj) {
  return project.update(types.updateProject, id, proj, []);
}

export function createProject(proj) {
  // console.log(proj);
  return project.create(types.createProject, proj, []);
}

const INITIAL_STATE = Map({
  project: {},
  projects: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.fetchProject:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          project: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.listProject:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          projects: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.updateProject:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          projects: actionSucceeds(action)
            ? state.get('projects').map(project => {
                if (project._id === action.body._id) {
                  return action.body;
                } else {
                  return project;
                }
              })
            : state.get('projects'),
        })
      );
    case types.deleteProject:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          projects: actionSucceeds(action)
            ? state.get('projects').filter(project => {
                return project._id !== action.body._id;
              })
            : state.get('projects'),
        })
      );
    case types.createProject:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          projects: actionSucceeds(action) ? state.get('projects').concat([action.body]) : state.get('projects'),
        })
      );
    default:
      return state;
  }
}
