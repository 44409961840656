import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DragDropContext } from 'react-beautiful-dnd';
import { listTask, editTask, fetchTask } from './../../../../../modules/task';
import Column from './SprintDnD/Column';
import './Sprint.css';

let blocked = {
  id: '0',
  type: 'BLOCKED',
  title: 'Blocked',
  items: [],
};
let backlog = {
  id: '1',
  type: 'BACKLOG',
  title: 'Backlog',
  items: [],
};
let in_progress = {
  id: '2',
  type: 'IN_PROGRESS',
  title: 'In Progress',
  items: [],
};
let completed = {
  id: '3',
  type: 'COMPLETED',
  title: 'Completed',
  items: [],
};

class Sprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colOrder: [blocked, backlog, in_progress, completed],
      retrieved: false,
    };
  }

  async componentDidMount() {
    await this.props.dispatch(listTask());
    let tasks = await this.props.task.get('tasks');

    if (tasks.length > 0) {
      // console.log(tasks)
      tasks.map((task, index) => {
        if (task.task_category === 'BLOCKED') {
          blocked.items[blocked.items.length] = task;
        } else if (task.task_category === 'BACKLOG') {
          backlog.items[blocked.items.length] = task;
        } else if (task.task_category === 'IN_PROGRESS') {
          in_progress.items[in_progress.items.length] = task;
        } else if (task.task_category === 'COMPLETED') {
          completed.items[completed.items.length] = task;
        }
        return 0;
      });
    } else {
      blocked.items = [];
      backlog.items = [];
      in_progress.items = [];
      completed.items = [];
    }
    this.setState({
      retrieved: true,
    });
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    //No destination
    if (!destination) {
      return;
    }

    //Location didnt change
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    //Between Columns
    if (destination.droppableId !== source.droppableId) {
      let newCategory;
      switch (destination.droppableId) {
        default:
          break;
        case '0':
          newCategory = 'BLOCKED';
          break;
        case '1':
          newCategory = 'BACKLOG';
          break;
        case '2':
          newCategory = 'IN_PROGRESS';
          break;
        case '3':
          newCategory = 'COMPLETED';
          break;
      }
      this.addNew(draggableId, newCategory);
    }
    //Single Column
    else {
      //Not Implemented Yet
    }
  };

  async addNew(id, newCategory) {
    let task = await this.props.dispatch(fetchTask(id));
    task.body.task_category = newCategory;
    await this.props.dispatch(editTask(id, task.body));
    await this.props.dispatch(listTask());
  }

  render() {
    return (
      <div className="sprint-container">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <ul className="dnd-list">
            {this.state.retrieved &&
              this.state.colOrder.map((col, index) => {
                // console.log(col)
                return (
                  <li className="column-container">
                    <Column key={col.id} col={col} tasks={col.items} object={this.props.object} />
                  </li>
                );
              })}
          </ul>
        </DragDropContext>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(Sprint)
);
