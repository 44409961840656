import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select from '../../../../Select/Select';
import Dropzone from 'react-dropzone';
import './Files.css';

class Files extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: '',
      file: null,
    };
  }

  handleUploadFileClick() {
    console.log('Upload File');
  }

  render() {
    return (
      <div className="files-container">
        <div className="search-panel">
          <span className="filter-header">Filter By:</span>
          <div className="filter-search">
            <Select
              className="filter-select"
              // placeholder={'Filter'}
              value={this.state.filter}
              onChange={value => {
                this.setState({ filter: value });
              }}
              options={['Created (Newest)', 'Created (Oldest)', 'Updated (Newest)', 'Updated (Oldest)']}
            />
          </div>

          <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className="add-file" {...getRootProps()}>
                  <input className="add-file-input" {...getInputProps()} />
                  <span className="add-file-span">Upload File</span>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(Files)
);
