import React from 'react';
import ToolbarButton from '../ToolbarButtons/ToolbarButton';
import SubNav from '../SubNav/SubNav';
import {
  faUser,
  faBuilding,
  faFolder,
  faAddressBook,
  faChevronRight,
  faChevronLeft,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { userActions, contactActions, companyActions, projectActions, settingsActions } from './SubNavActions';
import largeLogo from '../../../images/skiplistLogo.png';
import smallLogo from '../../../images/skiplistIcon.png';
import './Toolbar.css';
// eslint-disable-next-line
import { createSecureContext } from 'tls';

class Toolbar extends React.Component {
  sidebarItems = [
    {
      title: 'Users',
      icon: faUser,
      actions: userActions,
    },
    {
      title: 'Projects',
      icon: faFolder,
      actions: projectActions,
    },
    {
      title: 'Contacts',
      icon: faAddressBook,
      actions: contactActions,
    },
    {
      title: 'Companies',
      icon: faBuilding,
      actions: companyActions,
    },
    {
      title: 'Settings',
      icon: faCog,
      actions: settingsActions,
    },
  ];
  constructor() {
    super();
    this.state = {
      isMini: true,
      activeSidebar: '',
      component: null,
      subNavCollapse: false,
      prev: '',
    };
  }

  render() {
    const props = this.props;
    if (!props.show) {
      return (
        <div className="navigation-area">
          <div className={this.state.isMini ? 'mini-toolbar' : 'toolbar'}>
            <img
              src={this.state.isMini ? smallLogo : largeLogo}
              height={80}
              alt="Logo"
              style={{ marginBottom: '5px' }}
            />
            <ToolbarButton
              title={'Collapse'}
              isMini={this.state.isMini}
              icon={this.state.isMini ? faChevronRight : faChevronLeft}
              showSidebar={false}
              onClick={() => {
                this.setState({
                  isMini: !this.state.isMini,
                });
              }}
            />
            {this.sidebarItems.map((item, index) => {
              return (
                <ToolbarButton
                  key={index}
                  title={item.title}
                  icon={item.icon}
                  isMini={this.state.isMini}
                  sidebarComponent={<SubNav title={item.title} actions={item.actions} />}
                  showSidebar={this.state.activeSidebar === item.title}
                  onClick={subcomponent => {
                    this.setState({
                      activeSidebar: this.state.activeSidebar !== item.title ? item.title : '',
                      component: subcomponent,
                    });
                  }}
                  onClickCollapse={current => {
                    let prevTemp = current;
                    let collapseTemp = '';

                    if (this.state.prev === current) {
                      collapseTemp = true;
                      prevTemp = '';
                    } else {
                      collapseTemp = false;
                    }

                    this.setState({
                      prev: prevTemp,
                      subNavCollapse: collapseTemp,
                    });
                  }}
                />
              );
            })}
          </div>
          <div className={'sub-nav-container'}>
            {this.state.component && !this.state.subNavCollapse ? this.state.component : null}
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default Toolbar;
