import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Droppable } from 'react-beautiful-dnd';
import Task from './Task';
import './Column.css';
import Popup from './Popup';
import { deleteTask, listTask, fetchTask, editTask } from '../../../../../../modules/task';

class Column extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addTaskClicked: false,
    };
  }

  async componentDidMount() {
    await this.props.dispatch(listTask());
  }

  async taskDelete(id) {
    await this.props.dispatch(deleteTask(id));
    await this.props.dispatch(listTask());
  }

  async taskEdit(id, entry) {
    let temp = await this.props.dispatch(fetchTask(id));
    let task = temp.body;
    task['description'] = entry.entry;

    await this.props.dispatch(editTask(id, task));
    await this.props.dispatch(listTask());
  }

  handleAddTaskClick() {
    this.setState({
      addTaskClicked: !this.state.addTaskClicked,
    });
  }

  render() {
    return (
      <div className="column-list-container">
        <h2 className="column-title">{this.props.col.title}</h2>
        <button className="add-task-button" onClick={this.handleAddTaskClick.bind(this)}>
          <span>Add {this.props.col.title} Task</span>
        </button>

        <Droppable droppableId={this.props.col.id}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                marginTop: '10px',
                minHeight: '770px',
                padding: '5%',
                backgroundColor: snapshot.isDraggingOver ? 'lightBlue' : 'white',
                transition: 'background-color 0.3s ease',
              }}
            >
              {this.props.task
                .get('tasks')
                .map((task, index) =>
                  task.task_category === this.props.col.type && task.project.id === this.props.object.id ? (
                    <Task
                      key={task.id}
                      task={task}
                      index={index}
                      innerRef={provided.innerRef}
                      delete={id => this.taskDelete(id)}
                      edit={(id, entry) => this.taskEdit(id, entry)}
                    />
                  ) : null
                )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {this.state.addTaskClicked && (
          <Popup
            closePopup={this.handleAddTaskClick.bind(this)}
            object={this.props.object}
            type={this.props.col.type}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(Column)
);
