import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../../components/Form/Form';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { createContact, listContact } from '../../../modules/contact';
import './ContactForm.css';
import FormColumn from '../../../components/FormColumn/FormColumn';
// import Select from '../../../components/Select/Select';
import Typeahead from '../../../components/Typeahead/Typeahead';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: {
        name: '',
        title: '',
        department: '',
        location: '',
        email: '',
        secondary_email: '',
        mobile_number: '',
        work_number: '',
        personal_number: '',
        source: '',
        description: '',
        company: null,
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(listContact());
  }

  async onClick() {
    try {
      this.props.dispatch(createContact(this.state.contact));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="contact-form">
        <div className="form-header">
          <h4>{'Create Contact'}</h4>
        </div>
        <Form className="contact">
          <FormColumn title="Personal Info">
            <Input
              type={'text'}
              placeholder={'Name'}
              value={this.state.name}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, name: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Title'}
              value={this.state.title}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, title: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Department'}
              value={this.state.department}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, department: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Location'}
              value={this.state.location}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, location: value } });
              }}
            />
          </FormColumn>
          <FormColumn title="Contact Info">
            <Input
              type={'text'}
              placeholder={'Email'}
              value={this.state.email}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, email: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Secondary Email'}
              value={this.state.secondary_email}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, secondary_email: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Mobile Number'}
              value={this.state.mobile_number}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, mobile_number: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Work Number'}
              value={this.state.work_number}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, work_number: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Personal Number'}
              value={this.state.personal_number}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, personal_number: value } });
              }}
            />
          </FormColumn>
          <FormColumn title="Comapany Info">
            <Input
              type={'text'}
              placeholder={'Source'}
              value={this.state.source}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, source: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Description'}
              value={this.state.description}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, description: value } });
              }}
            />
            <Typeahead
              endpoint={'company'}
              value={this.state.contact.company}
              placeholder={'Company'}
              labelKey={'name'}
              onSelect={company => {
                this.setState({ contact: { ...this.state.contact, company: company.id } });
              }}
            />

            {/*
                  --------------------------Need to add Address-----------------------------------------------------------------------------------------
              */}
          </FormColumn>

          <Button className="contact-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
        </Form>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(ContactForm)
);
