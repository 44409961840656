import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../Form/Form';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { editContact } from '../../../modules/contact';
import FormColumn from '../../FormColumn/FormColumn';
import './EditForm.css';
import Typeahead from '../../../components/Typeahead/Typeahead';

class ContactEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: {
        name: this.props.data['name'],
        title: this.props.data['title'],
        department: this.props.data['department'],
        location: this.props.data['location'],
        email: this.props.data['email'],
        secondary_email: this.props.data['secondary_email'],
        mobile_number: this.props.data['mobile_number'],
        work_number: this.props.data['work_number'],
        personal_number: this.props.data['personal_number'],
        source: this.props.data['source'],
        description: this.props.data['description'],
        company: this.props.data['company'],
      },
    };
  }

  async onClick() {
    try {
      this.props.dispatch(editContact(this.props.id, this.state.contact));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="edit-form">
        <Form className="contact">
          <FormColumn title="Edit Personal Info">
            <Input
              type={'text'}
              title={'Name'}
              placeholder={this.state.contact.name}
              value={this.state.contact.name}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, name: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Title'}
              placeholder={this.state.contact.title}
              value={this.state.contact.title}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, title: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Department'}
              placeholder={this.state.contact.department}
              value={this.state.contact.department}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, department: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Location'}
              placeholder={this.state.contact.location}
              value={this.state.contact.location}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, location: value } });
              }}
            />
          </FormColumn>
          <FormColumn title="Edit Contact Info">
            <Input
              type={'text'}
              title={'Email'}
              placeholder={this.state.contact.email}
              value={this.state.contact.email}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, email: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Secondary Email'}
              placeholder={this.state.contact.secondary_email}
              value={this.state.contact.secondary_email}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, secondary_email: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Mobile Number'}
              placeholder={this.state.contact.mobile_number}
              value={this.state.contact.mobile_number}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, mobile_number: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Work Number'}
              placeholder={this.state.contact.work_number}
              value={this.state.contact.work_number}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact.contact, work_number: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Personal Number'}
              placeholder={this.state.contact.personal_number}
              value={this.state.contact.personal_number}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, personal_number: value } });
              }}
            />
          </FormColumn>
          <FormColumn title="Edit Comapany Info">
            <Input
              type={'text'}
              title={'Source'}
              placeholder={this.state.contact.source}
              value={this.state.contact.source}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, source: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Description'}
              placeholder={this.state.contact.description}
              value={this.state.contact.description}
              onChange={value => {
                this.setState({ contact: { ...this.state.contact, description: value } });
              }}
            />
            <Typeahead
              endpoint={'company'}
              value={this.state.contact.company}
              placeholder={'Company'}
              labelKey={'name'}
              onSelect={company => {
                this.setState({ contact: { ...this.state.contact, company: company.id } });
              }}
            />
          </FormColumn>
          <Button className="contact-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
        </Form>
      </div>
    );
  }
}
export default withRouter(
  connect(state => {
    return state;
  })(ContactEditForm)
);
