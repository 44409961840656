import React from 'react';
import './Input.css';
import Form from 'react-bootstrap/Form';

class Input extends React.Component {
  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.title ? this.props.title : this.props.placeholder}</Form.Label>
        <Form.Control
          onChange={e => this.props.onChange(e.target.value)}
          type={this.props.type}
          placeholder={this.props.placeholder}
        />
      </Form.Group>
    );
  }
}

export default Input;
