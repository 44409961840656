import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// eslint-disable-next-line
import fetchCompany from '../../../../modules/company';
import './RightNav.css';
// eslint-disable-next-line
import Button from '../../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

class RightNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {
        // name: this.props.data['name'],
        // website: this.props.data['website'],
        // industry: this.props.data['industry'],
        // description: this.props.data['description'],
        // type: this.props.data['type'],
      },
      logo: this.props.logo,
      collapsed: false,
    };
  }

  // async componentDidMount() {
  //   try {
  //     this.setState({
  //       company: this.props.dispatch(fetchCompany(this.props.id)),
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  handleClick() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    //  let company = this.props.dispatch(fetchCompany(this.props.id));

    if (this.state.collapsed) {
      return (
        <div className="mini-contact-panel">
          <div className="header">
            <button
              className="contact-button"
              onClick={() => {
                this.handleClick();
              }}
            >
              <FontAwesomeIcon size="3x" icon={faChevronRight} />
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="contact-panel">
          <div className="header">
            <button
              className="contact-button"
              onClick={() => {
                this.handleClick();
              }}
            >
              <FontAwesomeIcon size="3x" icon={faChevronLeft} />
            </button>
            <p className="contact-panel-header">Contacts</p>
          </div>
          <div className="contact-list" />
        </div>
      );
    }
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(RightNav)
);
