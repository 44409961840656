/** @format */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import contact from './contact';
import company from './company';
import companyNote from './companyNote';
import contactNote from './contactNote';
import projectNote from './projectNote';
import user from './user';
import project from './project';
import project_survey from './project_survey';
import task from './task';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    company,
    companyNote,
    contactNote,
    contact,
    user,
    project,
    project_survey,
    projectNote,
    task,
  });
