/** @format */

import request from './api';

class Entity {
  constructor({ path, query }) {
    this.path = path;
    this.query = query;
  }

  list(type, query) {
    query = query || this.query;
    return request(type, this.path, 'GET', null, query);
  }

  create(type, payload, query) {
    query = query || this.query;
    return request(type, this.path, 'POST', payload, query);
  }

  update(type, id, payload, query) {
    const url = `${this.path}/${id}`;
    query = query || this.query;
    return request(type, url, 'PATCH', payload, query);
  }

  get(type, id, query) {
    const url = `${this.path}/${id}`;
    query = query || this.query;
    return request(type, url, 'GET', null, query);
  }

  delete(type, id) {
    const url = `${this.path}/${id}`;
    return request(type, url, 'DELETE');
  }
}

export const user = new Entity({
  path: '/user',
});

export const contact = new Entity({
  path: '/contact',
});

export const contact_note = new Entity({
  path: '/contact_note',
});

export const company = new Entity({
  path: '/company',
});

export const company_note = new Entity({
  path: '/company_note',
});

export const project = new Entity({
  path: '/project',
});

export const project_survey = new Entity({
  path: '/project_survey',
});

export const project_note = new Entity({
  path: '/project_note',
});

export const task = new Entity({
  path: '/task',
});
