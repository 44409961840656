import React from 'react';
import './ActionButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';

class ActionButton extends React.Component {
  constructor() {
    super();

    this.state = { clicked: false };
  }

  handleClick() {
    this.props.history.push(this.props.url);
  }

  render() {
    return (
      <button className="action-class-button" onClick={this.handleClick.bind(this)}>
        <FontAwesomeIcon width={20} height={20} icon={this.props.icon} style={{ marginRight: 15, marginLeft: 10 }} />
        <span className="button-title">{this.props.title}</span>
      </button>
    );
  }
}

export default withRouter(ActionButton);
