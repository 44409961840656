/** @format */

import { company } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createCompany: 'COMPANY_CREATE',
  updateCompany: 'COMPANY_UPDATE',
  listCompany: 'COMPANY_LIST',
  fetchCompany: 'COMPANY_FETCH',
  deleteCompany: 'COMPANY_DELETE',
};

export function deleteCompany(id) {
  return company.delete(types.deleteCompany, id);
}

export function fetchCompany(id) {
  return company.get(types.fetchCompany, id, []);
}

export function listCompany() {
  return company.list(types.listCompany, []);
}

export function editCompany(id, comp) {
  return company.update(types.updateCompany, id, comp, []);
}

export function createCompany(comp) {
  return company.create(types.createCompany, comp, []);
}

const INITIAL_STATE = Map({
  company: {},
  companies: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.fetchCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          company: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.listCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.updateCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action)
            ? state.get('companies').map(company => {
                if (company._id === action.body._id) {
                  return action.body;
                } else {
                  return company;
                }
              })
            : state.get('companies'),
        })
      );
    case types.deleteCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action)
            ? state.get('companies').filter(company => {
                return company._id !== action.body._id;
              })
            : state.get('companies'),
        })
      );
    case types.createCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action) ? state.get('companies').concat([action.body]) : state.get('companies'),
        })
      );
    default:
      return state;
  }
}
