import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Table from '../../../components/Table/Table';

import { listProject } from '../../../modules/project';
import './ProjectList.css';

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(listProject());
  }

  async onClick() {}

  signOut() {
    this.setState({ user: null });
  }

  render() {
    const projects =
      this.props.project && this.props.project.get('projects') && this.props.project.get('projects').length > 0
        ? this.props.project.get('projects')
        : [];
    const headers = projects && projects.length > 0 ? Object.keys(projects[0]) : [];
    return (
      <div className="project-list">
        <div>
          <h4>Project List</h4>
        </div>
        <Table columnHeaders={headers} data={projects} type="project" />
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(ProjectList)
);
