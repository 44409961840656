/** @format */

import { project_note } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createProjectNote: 'PROJECT_NOTE_CREATE',
  updateProjectNote: 'PROJECT_NOTE_UPDATE',
  listProjectNote: 'PROJECT_NOTE_LIST',
  fetchProjectNote: 'PROJECT_NOTE_FETCH',
  deleteProjectNote: 'PROJECT_NOTE_DELETE',
};

export function deleteProjectNote(id) {
  return project_note.delete(types.deleteProjectNote, id);
}

export function fetchProjectNote(id) {
  return project_note.get(types.fetchProjectNote, id, []);
}

export function listProjectNote() {
  return project_note.list(types.listProjectNote, []);
}

export function editProjectNote(id, comp) {
  return project_note.update(types.updateProjectNote, id, comp, []);
}

export function createProjectNote(comp) {
  return project_note.create(types.createProjectNote, comp, []);
}

const INITIAL_STATE = Map({
  project_note: {},
  notes: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.fetchProjectNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          project_note: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.listProjectNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.updateProjectNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action)
            ? state.get('notes').map(project_note => {
                if (project_note.id === action.body._id) {
                  return action.body;
                } else {
                  return project_note;
                }
              })
            : state.get('notes'),
        })
      );
    case types.deleteProjectNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action)
            ? state.get('notes').filter(project_note => {
                return project_note.id !== action.body._id;
              })
            : state.get('notes'),
        })
      );
    case types.createProjectNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action) ? state.get('notes').concat([action.body]) : state.get('notes'),
        })
      );
    default:
      return state;
  }
}
