import moment from 'moment';

export function actionSucceeds(action) {
  // validate action success in redux
  return action.body && (action.body.code === undefined || action.body.code < 400);
}

export function arrayToPairedArray(array) {
  // param: [1, 2, 3, 4, 5, 6]
  // return: [[1, 2], [3, 4], [5, 6]]
  // (last element will be a single subarray if it's an odd number)
  return array
    .map(function(e, i) {
      return i % 2 === 0 ? array.slice(i, i + 2) : null;
    })
    .filter(item => item);
}

export function arrayToObject(array, key = 'id') {
  // param:  [{id: 1, name: 'A'}, {id: 2, name: 'B'}, {id: 3, name: 'C'}], 'id'
  // return: {1: {id: 1, name: 'A'}, 2: {id: 2, name: 'B'}, 3: {id: 3, name: 'C'}}
  return array.reduce((parent, object) => {
    parent[object[key]] = object;
    return parent;
  }, {});
}

export function arrayToObjectArray(array, key) {
  // param:  [{id: 1, name: 'A', empId: 1}, {id: 2, name: 'B', empId: 1}, {id: 3, name: 'C', empId: 2}], 'id'
  // return: {1: [{id: 1, name: 'A', empId: 1}, {id: 2, name: 'B', empId: 1}], 2: [{id: 3, name: 'C', empId: 2}]}
  return array.reduce((parent, object) => {
    parent[object[key]] = parent[object[key]] ? [...parent[object[key]], object] : [object];
    return parent;
  }, {});
}

export function parseSsn(value) {
  let val = value.replace(/\D/g, '');
  let newVal = '';
  if (val.length > 3 && val.length < 6) {
    newVal += val.substr(0, 3) + '-';
    val = val.substr(3);
  }
  if (val.length > 5) {
    newVal += val.substr(0, 3) + '-';
    newVal += val.substr(3, 2) + '-';
    val = val.substr(5);
  }
  newVal += val;
  return newVal.substring(0, 11);
}

export function parsePhone(value) {
  const stripped = value.replace(/\D/g, '');
  const formatted = stripped.replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, function(_, p1, p2, p3, p4) {
    let output = '';
    if (p1) output = `(${p1}`;
    if (p2) output += `${p2})`;
    if (p3) output += ` ${p3}`;
    if (p4) output += `-${p4}`;
    return output;
  });
  return formatted;
}

export function parseDate(value) {
  return moment(value).format('MM/DD/YYYY');
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
