import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../../components/Form/Form';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
// import { company } from '../../../modules/entities';
import { createCompany } from '../../../modules/company';
import FormColumn from '../../../components/FormColumn/FormColumn';
import { listCompany } from '../../../modules/company';
import './CompanyForm.css';
import Select from '../../../components/Select/Select';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {
        name: '',
        website: '',
        industry: '',
        description: '',
        type: 'MIDMARKET',
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(listCompany());
    console.log(this.props);
  }

  async onClick() {
    try {
      this.props.dispatch(createCompany(this.state.company));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="company-form">
        <Form className="company">
          <FormColumn title="Company Info">
            <Input
              type={'text'}
              placeholder={'Name'}
              value={this.state.company.name}
              onChange={value => {
                this.setState({ company: { ...this.state.company, name: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Website'}
              value={this.state.company.website}
              onChange={value => {
                this.setState({ company: { ...this.state.company, website: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Industry'}
              value={this.state.company.industry}
              onChange={value => {
                this.setState({ company: { ...this.state.company, industry: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Description'}
              value={this.state.company.description}
              onChange={value => {
                this.setState({ company: { ...this.state.company, description: value } });
              }}
            />
            <Select
              placeholder={'Company Type'}
              value={this.state.company.type}
              onChange={value => {
                this.setState({ company: { ...this.state.company, type: value } });
              }}
              options={['MIDMARKET', 'ENTERPRISE', 'STARTUP', 'STRATEGIC']}
            />
          </FormColumn>
          <Button className="company-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
        </Form>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(CompanyForm)
);
