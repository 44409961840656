import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../../components/Form/Form';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { createUser, listUser } from '../../../modules/user';
import './UserForm.css';
import Select from '../../../components/Select/Select';
import FormColumn from '../../../components/FormColumn/FormColumn';

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: '',
        password: '',
        role: 'EMPLOYEE',
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(listUser());
  }

  async onClick() {
    try {
      this.props.dispatch(createUser(this.state.user));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="user-form">
        <div className="form-header">
          <h4>{'Create User'}</h4>
        </div>
        <Form className="user">
          <FormColumn title="User Info">
            <Input
              type={'text'}
              placeholder={'Username'}
              value={this.state.user.username}
              onChange={value => {
                this.setState({ user: { ...this.state.user, username: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Password'}
              value={this.state.user.password}
              onChange={value => {
                this.setState({ user: { ...this.state.user, password: value } });
              }}
            />
            <Select
              placeholder={'User'}
              value={this.state.user.role}
              onChange={value => {
                this.setState({ user: { ...this.state.user, role: value } });
              }}
              options={['ADMIN', 'EMPLOYEE', 'CLIENT', 'CONTRACTOR']}
            />
          </FormColumn>
          <Button className="user-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
        </Form>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(UserForm)
);
