import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  createCompanyNote,
  listCompanyNote,
  deleteCompanyNote,
  editCompanyNote,
} from '../../../../../modules/companyNote';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import './Notes.css';
import Select from '../../../../Select/Select';
import {
  listContactNote,
  createContactNote,
  deleteContactNote,
  editContactNote,
} from '../../../../../modules/contactNote';
import {
  deleteProjectNote,
  listProjectNote,
  createProjectNote,
  editProjectNote,
} from '../../../../../modules/projectNote';

class Notes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      object_note: {
        entry: '',
        other: this.props.object,
        company: this.props.object,
        contact: this.props.object,
        project: this.props.object,
      },
      edit_object_note: {
        entry: '',
      },
      notes: '',
      addNoteClicked: false,
      editing: false,
      editingIndex: '',
      filter: 'Created (Newest)',
    };
  }

  componentDidMount() {
    switch (this.props.type) {
      default:
        break;

      case 'company':
        this.props.dispatch(listCompanyNote());
        break;

      case 'contact':
        this.props.dispatch(listContactNote());
        break;

      case 'project':
        this.props.dispatch(listProjectNote());
        break;
    }

    // this.props.contactNote.get('notes').map((entry) => {
    //   console.log(entry);
    //   return (null);
    // })
  }

  handleAddNoteClick() {
    this.setState({
      addNoteClicked: !this.state.addNoteClicked,
    });
  }

  async handleDeleteNote(id) {
    switch (this.props.type) {
      default:
        break;

      case 'company':
        await this.props.dispatch(deleteCompanyNote(id));
        this.props.dispatch(listCompanyNote());
        break;

      case 'contact':
        await this.props.dispatch(deleteContactNote(id));
        this.props.dispatch(listContactNote());
        break;

      case 'project':
        await this.props.dispatch(deleteProjectNote(id));
        this.props.dispatch(listProjectNote());
        break;
    }
    // window.location.reload();
  }

  handleEditNote(index) {
    this.setState({
      editing: !this.state.editing,
      editingIndex: index,
    });
  }

  onChangeAddNote(event) {
    this.setState({ object_note: { ...this.state.object_note, entry: event.target.value } });
  }

  onChangeEditNote(event) {
    this.setState({ edit_object_note: { ...this.state.edit_object_note, entry: event.target.value } });
  }

  handleEditNoteCanel() {
    this.setState({
      editing: !this.state.editing,
    });
  }

  async handleEditNoteSubmit(id) {
    switch (this.props.type) {
      default:
        break;

      case 'company':
        await this.props.dispatch(editCompanyNote(id, this.state.edit_object_note));
        this.props.dispatch(listCompanyNote());
        break;

      case 'contact':
        await this.props.dispatch(editContactNote(id, this.state.edit_object_note));
        this.props.dispatch(listContactNote());
        break;

      case 'project':
        await this.props.dispatch(editProjectNote(id, this.state.edit_object_note));
        this.props.dispatch(listProjectNote());
        break;
    }

    this.setState({
      editing: !this.state.editing,
    });

    //TESTING
    // console.log(this.props.dispatch(fetchContactNote(8)));
    // console.log(this.props.dispatch(listContactNote()));
    // this.props.contactNote.get('notes').map((entry) => {
    //   console.log(entry);
    //   return (null);
    // })
  }

  async handleNewNoteClick() {
    switch (this.props.type) {
      default:
        break;

      case 'company':
        this.props.dispatch(createCompanyNote(this.state.object_note));
        break;

      case 'contact':
        this.props.dispatch(createContactNote(this.state.object_note));
        break;

      case 'project':
        this.props.dispatch(createProjectNote(this.state.object_note));
        break;
    }

    //Resets Container
    this.setState({
      addNoteClicked: !this.state.addNoteClicked,
    });
  }

  render() {
    const addNoteForm = (
      <div>
        <form className="add-note-form">
          <textarea className="add-note-textarea" onChange={this.onChangeAddNote.bind(this)}>
            {/* New Note... */}
          </textarea>
          <button className="add-note-input" type="button" onClick={() => this.handleNewNoteClick()}>
            <FontAwesomeIcon icon={faCheck} />
          </button>

          <button className="add-note-cancel" type="button" onClick={() => this.handleAddNoteClick()}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </form>
      </div>
    );

    let viewNotes = (
      <div className="view-notes">
        {(this.props.type === 'company'
          ? this.props.companyNote.get('notes')
          : this.props.type === 'contact'
          ? this.props.contactNote.get('notes')
          : this.props.projectNote.get('notes')
        ).map((notes, index) => {
          if (
            (this.props.type === 'company' && notes.company.id === this.props.object.id) ||
            (this.props.type === 'contact' && notes.contact.id === this.props.object.id) ||
            (this.props.type === 'project' && notes.project.id === this.props.object.id)
          ) {
            if (this.state.editing && this.state.editingIndex === index) {
              return (
                <div>
                  <textarea className="edit-note-textarea" onChange={this.onChangeEditNote.bind(this)}>
                    {notes.entry}
                  </textarea>
                  <button className="editing-note-delete" type="button" onClick={() => this.handleEditNoteCanel()}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <button
                    className="editing-note-edit"
                    type="button"
                    onClick={() => this.handleEditNoteSubmit(notes.id)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </div>
              );
            } else {
              // console.log(notes.entry)
              return (
                <div>
                  <textarea readOnly className="view-note-textarea" value={notes.entry} />

                  <button className="view-note-delete" type="button" onClick={() => this.handleDeleteNote(notes.id)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>

                  <button className="view-note-edit" type="button" onClick={() => this.handleEditNote(index)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                </div>
              );
            }
          } else {
            return null;
          }
        })}
      </div>
    );

    return (
      <div className="note-content">
        <div className="search-panel">
          <span className="filter-header">Filter By:</span>
          <div className="filter-search">
            <Select
              className="filter-select"
              // placeholder={'Filter'}
              value={this.state.filter}
              onChange={value => {
                this.setState({ filter: value });
              }}
              options={['Created (Newest)', 'Created (Oldest)', 'Updated (Newest)', 'Updated (Oldest)', 'Company']}
            />
          </div>
          <div className="add-note">
            <button className="add-note-button" onClick={() => this.handleAddNoteClick()}>
              <span>Add Note</span>
            </button>
          </div>
        </div>

        <div className="notes-view">
          {this.state.addNoteClicked && addNoteForm}
          {viewNotes}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(Notes)
);
