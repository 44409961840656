import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Form from 'react-bootstrap/Form';

const MenuItem = ({ item, onClick, labelKey }) => {
  return (
    <div onClick={() => onClick()}>
      <span>{item[labelKey]}</span>
    </div>
  );
};

export default class Typeahead extends React.Component {
  state = {
    isLoading: false,
    options: [],
  };
  render() {
    return (
      <Form.Group>
        {/* <Form.Label>{this.props.placeholder}</Form.Label> */}
        <Form.Label>{this.props.title ? this.props.title : this.props.placeholder}</Form.Label>
        <AsyncTypeahead
          labelKey={this.props.labelKey}
          id={this.props.placeholder + '_1'}
          options={this.state.options}
          isLoading={this.state.isLoading}
          minLength={2}
          onSearch={this._handleSearch}
          placeholder={this.props.placeholder}
          renderMenuItemChildren={(option, props) => {
            return (
              <MenuItem
                key={option.id}
                item={option}
                labelKey={this.props.labelKey}
                onClick={() => {
                  this.props.onSelect(option);
                }}
              />
            );
          }}
        />
      </Form.Group>
    );
  }

  _handleSearch = query => {
    this.setState({ isLoading: true });
    let token = '';
    if (localStorage.getItem('jwt')) {
      token = localStorage.getItem('jwt');
    }
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    };

    fetch(`${process.env.REACT_APP_API_SERVER}/${this.props.endpoint}?typeahead=${query}`, {
      method: 'GET',
      body: null,
      headers: new window.Headers(headers),
    })
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          isLoading: false,
          options: data,
          total_count: data.length,
        });
      });
  };
}
