import request from './api';

export const actions = {
  login: 'LOGIN',
};

export function login(username, password) {
  return request(actions.login, '/auth/login', 'POST', { username, password }, []);
}

const INITIAL_STATE = {
  user: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.login:
      return {
        ...state,
      };
    default:
      return state;
  }
}
