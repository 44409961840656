import React from 'react';
import Form from 'react-bootstrap/Form';

class Select extends React.Component {
  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.placeholder}</Form.Label>
        <Form.Control
          as="select"
          onChange={e => this.props.onChange(e.target.value)}
          placeholder={this.props.placeholder}
        >
          {this.props.options.map((option, index) => (
            <option key={index}>{option}</option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  }
}

export default Select;
