import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../../components/Form/Form';
import FormColumn from '../../../components/FormColumn/FormColumn';

import { createProjectSurvey } from '../../../modules/project_survey';
import './SurveyForm.css';
import Typeahead from '../../../components/Typeahead/Typeahead';
import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';

class SurveyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: { taken: false, type: 'POST SIGNING', data: {}, projectId: 0, contactId: 0, userId: 0 },
      survey_uuid: null,
    };
  }

  async onClick() {
    console.log(this.state.survey);
    const surveyResponse = await this.props.dispatch(createProjectSurvey(this.state.survey));
    if (surveyResponse && surveyResponse.body) {
      this.setState({ survey_uuid: surveyResponse.body.uuid });
    }
  }

  render() {
    return (
      <div className="project-list">
        <h1>Create Survey</h1>
        <Form>
          {this.state.survey_uuid && (
            <p>{`You have created a survey at ${process.env.REACT_APP_SURVEY_SERVER}/${this.state.survey_uuid}`}</p>
          )}
          <FormColumn>
            <Typeahead
              endpoint={'project'}
              placeholder={'Project'}
              labelKey={'name'}
              onSelect={project => {
                this.setState({ survey: { ...this.state.survey, projectId: project.id } });
              }}
            />
            <Select
              placeholder={'Type'}
              onChange={type => {
                this.setState({ survey: { ...this.state.survey, type: type } });
              }}
              options={['POST SIGNING', 'POST KICKOFF', 'DEVELOPER', 'MILESTONE', 'PRODUCT', 'POST PROJECT']}
            />
            <Button onClick={this.onClick.bind(this)} text={'Create Survey'} />
          </FormColumn>
        </Form>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(SurveyForm)
);
