import {
  faUser,
  faUserPlus,
  faBuilding,
  faPoll,
  faFolderOpen,
  faFolderPlus,
  faAddressBook,
  faChartBar,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

export const userActions = [
  {
    title: 'View Users',
    url: '/users',
    icon: faUser,
  },
  {
    title: 'Create User',
    url: '/user/create',
    icon: faUserPlus,
  },
];

export const companyActions = [
  {
    title: 'View Companies',
    url: '/companies',
    icon: faBuilding,
  },
  {
    title: 'Create Company',
    url: '/company/create',
    icon: faBuilding,
  },
];

export const projectActions = [
  {
    title: 'View Projects',
    url: '/projects',
    icon: faFolderOpen,
  },
  {
    title: 'Create Project',
    url: '/project/create',
    icon: faFolderPlus,
  },
  {
    title: 'Create Survey',
    url: '/project/survey',
    icon: faPoll,
  },
  {
    title: 'Upload Survey',
    url: '/project/survey/upload',
    icon: faUpload,
  },
  {
    title: 'View Surveys',
    url: '/project/view_surveys',
    icon: faChartBar,
  },
];

export const contactActions = [
  {
    title: 'View Contacts',
    url: '/contacts',
    icon: faAddressBook,
  },
  {
    title: 'Create Contact',
    url: '/contact/create',
    icon: faAddressBook,
  },
];

export const settingsActions = [];
