import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchCompany } from '../../../modules/company';
import LeftNav from './Panels/LeftNav';
import CenterNav from './Panels/CenterNav';
import RightNav from './Panels/RightNav';
import './View.css';
import { fetchContact } from '../../../modules/contact';
import { fetchProject } from '../../../modules/project';
import { fetchUser } from '../../../modules/user';

class View extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: '',
    };
  }

  async componentDidMount() {
    switch (this.props.location.state.type) {
      default:
        break;
      case 'company':
        this.props.dispatch(fetchCompany(this.props.location.state.id));
        break;

      case 'contact':
        this.props.dispatch(fetchContact(this.props.location.state.id));
        break;

      case 'project':
        await this.props.dispatch(fetchProject(this.props.location.state.id));
        break;

      case 'user':
        this.props.dispatch(fetchUser(this.props.location.state.id));
        break;
    }
  }

  render() {
    let object;
    switch (this.props.location.state.type) {
      default:
        break;
      case 'company':
        object = this.props.company.get('company');
        break;

      case 'contact':
        object = this.props.contact.get('contact');
        break;

      case 'project':
        object = this.props.project.get('project');
        break;

      case 'user':
        object = this.props.user.get('user');
        break;
    }
    if (object.id) {
      return (
        <div className="project-view">
          <LeftNav object={object} type={this.props.location.state.type} logo={this.state.logo} />
          <CenterNav object={object} type={this.props.location.state.type} />
          <RightNav object={object} type={this.props.location.state.type} />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(View)
);
