import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../Form/Form';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { editUser } from '../../../modules/user';
import FormColumn from '../../FormColumn/FormColumn';
import './EditForm.css';
import Select from '../../../components/Select/Select';

/*
  Currently the form allows for the password to be change
*/

class UserEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: this.props.data['username'],
        password: this.props.data['password'],
        role: this.props.data['role'],
      },
    };
  }

  async onClick() {
    try {
      this.props.dispatch(editUser(this.props.id, this.state.user));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="edit-form">
        <Form className="user">
          <FormColumn title="Edit User Info">
            <Input
              type={'text'}
              title={'Username'}
              placeholder={this.state.user.username}
              value={this.state.user.username}
              onChange={value => {
                this.setState({ user: { ...this.state.user, username: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Passsword'}
              placeholder={this.state.user.password}
              value={this.state.user.password}
              onChange={value => {
                this.setState({ user: { ...this.state.user, password: value } });
              }}
            />
            <Select
              placeholder={'User'}
              value={this.state.user.role}
              onChange={value => {
                this.setState({ user: { ...this.state.user, role: value } });
              }}
              options={['ADMIN', 'EMPLOYEE', 'CLIENT', 'CONTRACTOR']}
            />
          </FormColumn>
          <Button className="user-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
        </Form>
      </div>
    );
  }
}
export default withRouter(
  connect(state => {
    return state;
  })(UserEditForm)
);
