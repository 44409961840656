import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { listProjectSurvey } from './../../modules/project_survey';
import './SurveyData.css';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var CanvasJSReact = require('./../../components/Charts/canvasjs.react');
// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

var data = new Map();

class SurveyData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [],
      values: [],
      graphClicked: '',
    };
  }

  async componentDidMount() {
    await this.props.dispatch(listProjectSurvey());
    let tempData = await this.props.project_survey.get('project_surveys');

    for (var i = 0; i < tempData.length; i++) {
      var s = tempData[i].data;
      let category;
      let value;

      if (this.props.id === tempData[i].projectId && tempData[i].taken && tempData[i].data.match(/:/g)) {
        for (var j = 0; j < tempData[i].data.match(/:/g).length; j++) {
          //Category
          category = s.substring(s.indexOf('"') + 1, s.indexOf('"', s.indexOf('"') + 1));
          s = s.substring(s.indexOf('"', s.indexOf('"') + 1) + 1);

          //Value
          if (j === tempData[i].data.match(/:/g).length - 1) {
            //If last in list
            value = s.substring(s.indexOf('"') + 1, s.indexOf('"', s.indexOf('"') + 1));
          } else {
            value = s.substring(s.indexOf('"') + 1, s.indexOf('"', s.indexOf('"') + 1));
          }
          s = s.substring(s.indexOf('",') + 1);
          // console.log("#" + j + " CAT: " + category + " VAL: " + value)

          //Map Insertion
          if (data.has(category)) {
            let tempVals = data.get(category);
            tempVals[tempVals.length] = value;
            data.set(category, tempVals);
          } else {
            data.set(category, [value]);
          }
        }
      }
    }

    this.setState({
      keys: [...data.keys()],
      values: [...data.values()],
    });
  }

  //Prettys Up the Keys
  prettyUp(ugly) {
    let count = ugly.split('_').length;
    let pretty = '';

    for (var i = 0; i < count; i++) {
      if (ugly.indexOf('_') === -1) {
        pretty += ugly.charAt(0).toUpperCase() + ugly.substring(1);
      } else {
        pretty += ugly.charAt(0).toUpperCase() + ugly.substring(1, ugly.indexOf('_')) + ' ';
        ugly = ugly.substring(ugly.indexOf('_') + 1);
      }
    }

    console.log(pretty);
    return pretty;
  }

  organize(values) {
    let organizied = {
      zero: 0,
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      six: 0,
      seven: 0,
      eight: 0,
      nine: 0,
      ten: 0,
    };

    values.forEach(element => {
      switch (element) {
        default:
          break;
        case '0':
          organizied.zero = organizied.zero + 1;
          break;
        case '1':
          organizied.one = organizied.one + 1;
          break;
        case '2':
          organizied.two = organizied.two + 1;
          break;
        case '3':
          organizied.three = organizied.three + 1;
          break;
        case '4':
          organizied.four = organizied.four + 1;
          break;
        case '5':
          organizied.five = organizied.five + 1;
          break;
        case '6':
          organizied.six = organizied.six + 1;
          break;
        case '7':
          organizied.seven = organizied.seven + 1;
          break;
        case '8':
          organizied.eight = organizied.eight + 1;
          break;
        case '9':
          organizied.nine = organizied.nine + 1;
          break;
        case '10':
          organizied.ten = organizied.ten + 1;
          break;
      }
    });

    return organizied;
  }

  render() {
    return (
      <div>
        <h1>Survey Data</h1>

        {this.state.keys.map((key, index) => {
          //Convert key into good-looking name
          let name = this.prettyUp(key);

          //Has Numbers
          if (this.state.values[index][0].length === 1) {
            let spread = this.organize(this.state.values[index]);

            const options = {
              title: {
                text: name,
              },
              data: [
                {
                  type: 'column',
                  dataPoints: [
                    { label: '0', y: spread.zero },
                    { label: '1', y: spread.one },
                    { label: '2', y: spread.two },
                    { label: '3', y: spread.three },
                    { label: '4', y: spread.four },
                    { label: '5', y: spread.five },
                    { label: '6', y: spread.six },
                    { label: '7', y: spread.seven },
                    { label: '8', y: spread.eight },
                    { label: '9', y: spread.nine },
                    { label: '10', y: spread.ten },
                  ],
                },
              ],
            };
            return (
              <div>
                <button
                  className="graph-button"
                  onClick={() => {
                    let temp = '';
                    if (this.state.graphClicked !== key) {
                      temp = key;
                    }
                    this.setState({ graphClicked: temp });
                  }}
                >
                  {name} (Graph){' '}
                  {this.state.graphClicked === key ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </button>

                <div className="graph-container">
                  {this.state.graphClicked === key && <CanvasJSChart options={options} />}
                </div>
              </div>
            );
          }
          //Words
          else {
            return (
              <div>
                <button
                  className="graph-button"
                  onClick={() => {
                    let temp = '';
                    if (this.state.graphClicked !== key) {
                      temp = key;
                    }
                    this.setState({ graphClicked: temp });
                  }}
                >
                  {name} (Written){' '}
                  {this.state.graphClicked === key ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </button>

                <div className="written-container">
                  {this.state.graphClicked === key &&
                    this.state.values[index].map((val, index) => {
                      return <div className="written-entry">{index + 1 + ') ' + val}</div>;
                    })}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(SurveyData)
);
