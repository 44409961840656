import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CSVReader from 'react-csv-reader';
import { listProject, fetchProject } from '../../modules/project';
import { createProjectSurvey } from './../../modules/project_survey';
import './UploadSurvey.css';

class UploadSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: { taken: false, type: '', data: {}, project: {}, contactId: 0, userId: 0, uuid: null },
      data: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(listProject());
  }

  async processData(input) {
    this.setState({ data: input });

    for (let i = 1; i < input.length; i++) {
      this.setState({
        survey: {
          type: input[i][1] === 'PRODUCT' || input[i][1] === 'INTERNAL' ? 'DEVELOPER' : input[i][1], //INTERNAL AND PRODUCTS IS NOT WORKING
          data: input[i][2] ? input[i][2] : 'None',
          project: input[i][5] !== null ? (await this.props.dispatch(fetchProject(input[i][5]))).body : null,
          contactId: input[i][6],
          uuid: input[i][7],
          taken: input[i][8] === 'f' ? false : true,
          userId: input[i][9],
        },
      });

      try {
        await this.props.dispatch(createProjectSurvey(this.state.survey));
      } catch (error) {
        console.log(error);
      }
    }
  }

  // Sample Data:
  //   0: "id"
  //   1: "type"
  //   2: "data"
  //   3: "createdAt"
  //   4: "updatedAt"
  //   5: "projectId"
  //   6: "contactId"
  //   7: "uuid"
  //   8: "taken"
  //   9: "userId"

  render() {
    return (
      <div className="upload-container">
        <h1 className="upload-header">Upload Survey</h1>
        <span>Select CSV file with surveys to upload</span>
        <CSVReader cssClass="csv-reader-input" onFileLoaded={input => this.processData(input)} />
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(UploadSurvey)
);
