/** @format */

import { company_note } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createCompanyNote: 'COMPANY_NOTE_CREATE',
  updateCompanyNote: 'COMPANY_NOTE_UPDATE',
  listCompanyNote: 'COMPANY_NOTE_LIST',
  fetchCompanyNote: 'COMPANY_NOTE_FETCH',
  deleteCompanyNote: 'COMPANY_NOTE_DELETE',
};

export function deleteCompanyNote(id) {
  return company_note.delete(types.deleteCompanyNote, id);
}

export function fetchCompanyNote(id) {
  return company_note.get(types.fetchCompanyNote, id, []);
}

export function listCompanyNote() {
  return company_note.list(types.listCompanyNote, []);
}

export function editCompanyNote(id, comp) {
  return company_note.update(types.updateCompanyNote, id, comp, []);
}

export function createCompanyNote(comp) {
  return company_note.create(types.createCompanyNote, comp, []);
}

const INITIAL_STATE = Map({
  company_note: {},
  notes: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.fetchCompanyNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          company_note: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.listCompanyNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.updateCompanyNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action)
            ? state.get('notes').map(company_note => {
                if (company_note.id === action.body._id) {
                  return action.body;
                } else {
                  return company_note;
                }
              })
            : state.get('notes'),
        })
      );
    case types.deleteCompanyNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action)
            ? state.get('notes').filter(company_note => {
                return company_note.id !== action.body._id;
              })
            : state.get('notes'),
        })
      );
    case types.createCompanyNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action) ? state.get('notes').concat([action.body]) : state.get('notes'),
        })
      );
    default:
      return state;
  }
}
