import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchProjectSurvey } from '../../../modules/project_survey';

class SurveyView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logo: '',
    };
  }

  async componentDidMount() {
    this.props.dispatch(fetchProjectSurvey(this.props.location.state.id));
  }

  render() {
    return <div />;
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(SurveyView)
);
