import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../Form/Form';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { editCompany } from '../../../modules/company';
import FormColumn from '../../FormColumn/FormColumn';
import './EditForm.css';
import Select from '../../../components/Select/Select';

class CompanyEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {
        name: this.props.data['name'],
        website: this.props.data['website'],
        industry: this.props.data['industry'],
        description: this.props.data['description'],
        type: this.props.data['type'],
      },
    };
  }

  async onClick() {
    try {
      this.props.dispatch(editCompany(this.props.id, this.state.company));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="edit-form">
        <Form className="edit">
          <FormColumn title="Edit Company Info">
            <Input
              type={'text'}
              title={'Name'}
              placeholder={this.state.company.name}
              value={this.state.company.name}
              onChange={value => {
                this.setState({ company: { ...this.state.company, name: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Website'}
              placeholder={this.state.company.website}
              value={this.state.company.website}
              onChange={value => {
                this.setState({ company: { ...this.state.company, website: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Industry'}
              placeholder={this.state.company.industry}
              value={this.state.company.industry}
              onChange={value => {
                this.setState({ company: { ...this.state.company, industry: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Description'}
              placeholder={this.state.company.description}
              value={this.state.company.description}
              onChange={value => {
                this.setState({ company: { ...this.state.company, description: value } });
              }}
            />
            <Select
              placeholder={'Company Type'}
              value={this.state.company.type}
              onChange={value => {
                this.setState({ company: { ...this.state.company, type: value } });
              }}
              options={['MIDMARKET', 'ENTERPRISE', 'STARTUP', 'STRATEGIC']}
            />
          </FormColumn>
          <Button className="edit-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
        </Form>
      </div>
    );
  }
}
export default withRouter(
  connect(state => {
    return state;
  })(CompanyEditForm)
);
