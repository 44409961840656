import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import './LeftNav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

class LeftNav extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.object)
    this.state = {
      logo: this.props.logo,
      goBack: false,
      link: '',
    };
  }

  async handleBackClick(type) {
    let redirect;
    switch (type) {
      default:
        break;
      case 'company':
        redirect = 'companies';
        break;

      case 'contact':
        redirect = 'contacts';
        break;

      case 'project':
        redirect = 'projects';
        break;
    }

    this.setState({
      goBack: !this.state.goBack,
      link: redirect,
    });
  }

  render() {
    let specificHeaders = [];
    let specificInfo = [];

    switch (this.props.type) {
      default:
        break;

      case 'company':
        specificHeaders = ['Name', 'Website', 'Industry', 'Description', 'Type'];
        specificInfo = [
          this.props.object ? this.props.object.name : '',
          this.props.object ? this.props.object.website : '',
          this.props.object ? this.props.object.industry : '',
          this.props.object ? this.props.object.description : '',
          this.props.object ? this.props.object.type : '',
        ];

        break;

      case 'contact':
        specificHeaders = [
          'Name',
          'Title',
          'Department',
          'Location',
          'Email',
          'Secondary Email',
          'Mobile Number',
          'Work Number',
          'Personal Number',
          'Source',
          'Work Number',
          'Description',
          'Company',
        ];
        specificInfo = [
          this.props.object.name,
          this.props.object.title,
          this.props.object.department,
          this.props.object.location,
          this.props.object.email,
          this.props.object.secondary_email,
          this.props.object.mobile_number,
          this.props.object.work_number,
          this.props.object.personal_number,
          this.props.object.source,
          this.props.object.work_number,
          this.props.object.description,
          this.props.object.company ? this.props.object.company.name : '',
        ];
        break;

      case 'project':
        specificHeaders = [
          'Name',
          'Description',
          'Budget',
          'Sale Amount',
          'Project Category',
          'Payment Type',
          'Project Status',
          'Company',
          'Primary Contact',
          'Accounting Contact',
          'Contributors',
        ];
        specificInfo = [
          this.props.object.name,
          this.props.object.description,
          this.props.object.budget,
          this.props.object.sale_amount,
          this.props.object.project_category,
          this.props.object.payment_type,
          this.props.object.project_status,
          this.props.object.company ? this.props.object.company.name : '',
          this.props.object.primary_contact ? this.props.object.primary_contact.name : '',
          this.props.object.accounting_contact ? this.props.object.accounting_contact.name : '',
          this.props.object.contributors,
        ];
        break;

      case 'user':
        specificHeaders = ['Username', 'Role'];
        specificInfo = [this.props.object.username, this.props.object.role];
    }

    return (
      <div>
        <div className="sidebar">
          <div className="card-container">
            <div className="back-action">
              <button className="back-button" onClick={() => this.handleBackClick(this.props.type)}>
                <span className="back-button-text">
                  <FontAwesomeIcon width={35} height={35} icon={faArrowLeft} /> Back to{' '}
                  {this.props.type.charAt(0).toUpperCase() + this.props.type.substring(1) + ' Page'}
                </span>
              </button>
              {this.state.goBack && <Redirect to={'/' + this.state.link + ''} />}
            </div>
          </div>
          <div className="specific-info">
            <ul className="specific-info-list">
              {specificHeaders.map((specific, index) => {
                return (
                  <li className="info-entry">
                    <h5>{specific}</h5>
                    <p>
                      {specific === 'Contributors'
                        ? specificInfo[index].map(user => {
                            return <div>{user.username}</div>;
                          })
                        : specificInfo[index]
                        ? specificInfo[index]
                        : '-'}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(LeftNav)
);
