import React from 'react';
import Toolbar from '../../components/Menus/Toolbar/Toolbar';
import UsersList from '../Users/UsersList/UserList';
import UsersForm from '../Users/UsersForm/UserForm';
import ProjectsList from '../Projects/ProjectsList/ProjectList';
import ProjectsForm from '../Projects/ProjectsForm/ProjectForm';
import ProjectSurveyForm from '../Projects/SurveysForm/SurveyForm';
import CompaniesList from '../Companies/CompaniesList/CompanyList';
import CompaniesForm from '../Companies/CompaniesForm/CompanyForm';
import ContactsList from '../Contacts/ContactsList/ContactList';
import ContactsForm from '../Contacts/ContactsForm/ContactForm';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import Background from '../Background/Background';
import './Dashboard.css';

import View from '../../components/Table/ViewPages/View';
import ProjectSurveyList from '../Projects/ProjectSurveyList/ProjectSurveyList';
import SurveyView from '../../components/Table/ViewPages/SurveyView';
import UploadSurvey from '../Projects/UploadSurvey';

class Dashboard extends React.Component {
  state = {
    type: '',
    mode: '',
  };
  render() {
    // companyPath = findPath();

    return (
      <div className="dashboard">
        <div className="sidebar-container">
          <Toolbar />
        </div>

        <div className="view-container">
          <Switch>
            <Route exact path="/users" component={UsersList} />
            <Route path="/user/create" component={UsersForm} />

            <Route exact path="/projects" component={ProjectsList} />
            <Route path="/project/create" component={ProjectsForm} />

            <Route exact path="/project/survey" component={ProjectSurveyForm} />
            <Route path="/project/view_surveys" component={ProjectSurveyList} />
            <Route path="/project/survey/upload" component={UploadSurvey} />

            <Route exact path="/companies" component={CompaniesList} />
            <Route path="/company/create" component={CompaniesForm} />

            <Route path={`/company/view/`} component={View} />
            <Route path={`/contact/view/`} component={View} />
            <Route path={`/project/view/`} component={View} />
            <Route path={`/project_survey/view/`} component={SurveyView} />
            <Route path={`/user/view/`} component={View} />

            <Route exact path="/contacts" component={ContactsList} />
            <Route path="/contact/create" component={ContactsForm} />

            <Route path="/" component={Background} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
