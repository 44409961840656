import React from 'react';
import './Button.css';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CustomButton extends React.Component {
  render() {
    return (
      <Button
        className={this.props.className}
        type="button"
        variant="custom"
        onClick={e => {
          e.preventDefault();
          this.props.onClick(e);
        }}
      >
        {this.props.icon ? <FontAwesomeIcon icon={this.props.icon} /> : this.props.text}
      </Button>
    );
  }
}

export default CustomButton;
