import React from 'react';
import ReactDOM from 'react-dom';

import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import configureStore, { history } from './store';
import Login from './containers/Login/Login';
import Dashboard from './containers/Dashboard/Dashboard';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './index.css';
// eslint-disable-next-line no-unused-vars
import gothamBold from './fonts/gotham-bold.woff';
// eslint-disable-next-line no-unused-vars
import gothamBook from './fonts/gotham-book.woff';
// eslint-disable-next-line no-unused-vars
import gothamMedium from './fonts/gotham-medium.woff';

const store = configureStore({});
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
