/** @format */

import { contact_note } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createContactNote: 'CONTACT_NOTE_CREATE',
  updateContactNote: 'CONTACT_NOTE_UPDATE',
  listContactNote: 'CONTACT_NOTE_LIST',
  fetchContactNote: 'CONTACT_NOTE_FETCH',
  deleteContactNote: 'CONTACT_NOTE_DELETE',
};

export function deleteContactNote(id) {
  return contact_note.delete(types.deleteContactNote, id);
}

export function fetchContactNote(id) {
  return contact_note.get(types.fetchContactNote, id, []);
}

export function listContactNote() {
  return contact_note.list(types.listContactNote, []);
}

export function editContactNote(id, comp) {
  return contact_note.update(types.updateContactNote, id, comp, []);
}

export function createContactNote(comp) {
  return contact_note.create(types.createContactNote, comp, []);
}

const INITIAL_STATE = Map({
  contact_note: {},
  notes: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.fetchContactNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          contact_note: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.listContactNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.updateContactNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action)
            ? state.get('notes').map(contact_note => {
                if (contact_note.id === action.body._id) {
                  return action.body;
                } else {
                  return contact_note;
                }
              })
            : state.get('notes'),
        })
      );
    case types.deleteContactNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action)
            ? state.get('notes').filter(contact_note => {
                return contact_note.id !== action.body._id;
              })
            : state.get('notes'),
        })
      );
    case types.createContactNote:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          notes: actionSucceeds(action) ? state.get('notes').concat([action.body]) : state.get('notes'),
        })
      );
    default:
      return state;
  }
}
