import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Table from '../../../components/Table/Table';

import { listUser } from '../../../modules/user';
import './UserList.css';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(listUser());
  }

  async onClick() {}

  signOut() {
    this.setState({ user: null });
  }

  render() {
    const users =
      this.props.user && this.props.user.get('users') && this.props.user.get('users').length > 0
        ? this.props.user.get('users')
        : [];
    const headers = users && users.length > 0 ? Object.keys(users[0]).slice(0, 5) : [];
    return (
      <div className="user-list">
        <div>
          <h4>User List</h4>
        </div>
        <Table columnHeaders={headers} data={users} type="user" />
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(UserList)
);
