import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SurveyData from '../../../../../containers/Projects/SurveyData.js';
import './Surveys.css';

class Surveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="surveys-container">
        <SurveyData id={this.props.object.id} />
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(Surveys)
);
