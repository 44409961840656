import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Table from '../../../components/Table/Table';

import { listProjectSurvey } from '../../../modules/project_survey';
import './ProjectSurveyList.css';

class ProjectSurveyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(listProjectSurvey());
  }

  async onClick() {}

  signOut() {
    this.setState({ user: null });
  }

  render() {
    const survey =
      this.props.project_survey &&
      this.props.project_survey.get('project_surveys') &&
      this.props.project_survey.get('project_surveys').length > 0
        ? this.props.project_survey.get('project_surveys')
        : [];
    const headers = survey && survey.length > 0 ? Object.keys(survey[0]) : [];
    return (
      <div>
        <div>
          <h4>Project Survey List</h4>
        </div>
        <Table columnHeaders={headers} data={survey} type="project_survey" />
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(ProjectSurveyList)
);
