import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../../components/Form/Form';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { createProject, listProject } from '../../../modules/project';
import './ProjectForm.css';
import FormColumn from '../../../components/FormColumn/FormColumn';
import Select from '../../../components/Select/Select';
import Typeahead from '../../../components/Typeahead/Typeahead';

class ProjectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {
        name: '',
        project_category: 'POC',
        project_status: 'LEAD',
        payment_type: 'HOURLY',
        description: '',
        budget: 0,
        sale_amount: 0,
        primary_contact: null,
        accounting_contact: null,
        contributors: [],
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(listProject());
  }

  async onClick() {
    try {
      this.props.dispatch(createProject(this.state.project));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="project-form">
        <div className="form-header">
          <h4>{'Create Project'}</h4>
        </div>
        <Form className="project">
          <FormColumn title="Project Info">
            <Input
              type={'text'}
              placeholder={'Name'}
              value={this.state.project.name}
              onChange={value => {
                this.setState({ project: { ...this.state.project, name: value } });
              }}
            />
            <Input
              type={'text'}
              placeholder={'Description'}
              value={this.state.project.description}
              onChange={value => {
                this.setState({ project: { ...this.state.project, description: value } });
              }}
            />
            <Select
              placeholder={'Category'}
              value={this.state.project.project_category}
              onChange={value => {
                this.setState({ project: { ...this.state.project, project_category: value } });
              }}
              options={['POC', 'MVP', 'STANDARD', 'ENTERPRISE']}
            />
            <Typeahead
              endpoint={'contact'}
              value={this.state.project.primary_contact}
              placeholder={'Primary Contact'}
              labelKey={'name'}
              onSelect={contact => {
                this.setState({ project: { ...this.state.project, primary_contact: contact.id } });
              }}
            />
          </FormColumn>
          <FormColumn title="Affiliates">
            <Select
              placeholder={'Project Status'}
              value={this.state.project.project_status}
              onChange={value => {
                this.setState({ project: { ...this.state.project, project_status: value } });
              }}
              options={[
                'LEAD',
                'SCOPING',
                'DECISION PENDING',
                'DECISION MADE',
                'SIGNING',
                'CLOSED_WON',
                'CLOSED_LOST',
                'ACTIVE',
                'ARCHIVE',
              ]}
            />
            <Typeahead
              endpoint={'company'}
              value={this.state.project.company}
              placeholder={'Company'}
              labelKey={'name'}
              onSelect={company => {
                this.setState({ project: { ...this.state.project, company: company.id } });
              }}
            />
            <Typeahead
              endpoint={'user'}
              value={this.state.project.contributors}
              placeholder={'Contributors'}
              labelKey={'username'}
              onSelect={username => {
                console.log(username);
                this.state.project.contributors.push(username);
              }}
            />
          </FormColumn>
          <FormColumn title="Project Expenditure">
            <Input
              type={'text'}
              value={this.state.project.budget}
              placeholder={'Budget'}
              onChange={value => {
                this.setState({ project: { ...this.state.project, budget: value } });
              }}
            />
            <Input
              type={'text'}
              value={this.state.project.sale_amount}
              placeholder={'Sale Amount'}
              onChange={value => {
                this.setState({ project: { ...this.state.project, sale_amount: value } });
              }}
            />
            <Select
              placeholder={'Payment Type'}
              value={this.state.project.payment_type}
              onChange={value => {
                this.setState({ project: { ...this.state.project, payment_type: value } });
              }}
              options={['HOURLY', 'WEEKLY', 'FIXED_BID']}
            />
            <Typeahead
              endpoint={'contact'}
              value={this.state.project.accounting_contact}
              placeholder={'Accounting Contact'}
              labelKey={'name'}
              onSelect={contact => {
                this.setState({ project: { ...this.state.project, accounting_contact: contact.id } });
              }}
            />
          </FormColumn>
          <div className="submit-button">
            <Button className="project-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(ProjectForm)
);
