import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from '../../Form/Form';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { editProject } from '../../../modules/project';
import FormColumn from '../../FormColumn/FormColumn';
import './EditForm.css';
import Select from '../../../components/Select/Select';
import Typeahead from '../../../components/Typeahead/Typeahead';

class ProjectEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {
        name: this.props.data['name'],
        description: this.props.data['description'],
        budget: this.props.data['budget'],
        sale_amount: this.props.data['sale_amount'],
        project_category: this.props.data['project_category'],
        payment_type: this.props.data['payment_type'],
        project_status: this.props.data['project_status'],
        company: this.props.data['company'],
        primary_contact: this.props.data['primary_contact'],
        contributors: this.props.data['contributors'],

        accounting_contact: this.props.data['accounting_contact'],
      },
    };
  }

  async onClick() {
    try {
      this.props.dispatch(editProject(this.props.id, this.state.project));
    } catch (error) {
      console.error(error);
    }
    window.location.reload();
  }

  render() {
    return (
      <div className="edit-form">
        <Form className="project">
          <FormColumn title="Edit Project Info">
            <Input
              type={'text'}
              title={'Name'}
              placeholder={this.state.project.name}
              value={this.state.project.name}
              onChange={value => {
                this.setState({ project: { ...this.state.project, name: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Description'}
              placeholder={this.state.project.description}
              value={this.state.project.description}
              onChange={value => {
                this.setState({ project: { ...this.state.project, description: value } });
              }}
            />
            <Select
              placeholder={'Category'}
              value={this.state.project.project_category}
              onChange={value => {
                this.setState({ project: { ...this.state.project, project_category: value } });
              }}
              options={['POC', 'MVP', 'STANDARD', 'ENTERPRISE']}
            />
            <Typeahead
              endpoint={'contact'}
              title={'Primary Contact'}
              value={this.state.project.primary_contact}
              placeholder={this.state.project.primary_contact}
              labelKey={'name'}
              onSelect={contact => {
                this.setState({ project: { ...this.state.project, primary_contact: contact.id } });
              }}
            />
          </FormColumn>
          <FormColumn title="Edit Affiliates">
            <Select
              placeholder={'Project Status'}
              value={this.state.project.project_status}
              onChange={value => {
                this.setState({ project: { ...this.state.project, project_status: value } });
              }}
              options={[
                'LEAD',
                'SCOPING',
                'DECISION PENDING',
                'DECISION MADE',
                'SIGNING',
                'CLOSED_WON',
                'CLOSED_LOST',
                'ACTIVE',
                'ARCHIVE',
              ]}
            />
            <Typeahead
              endpoint={'contact'}
              value={this.state.project.company}
              placeholder={'Company'}
              labelKey={'name'}
              onSelect={company => {
                this.setState({ project: { ...this.state.project, company: company.id } });
              }}
            />
            <Typeahead
              endpoint={'user'}
              value={this.state.project.contributors}
              placeholder={'Contributors'}
              labelKey={'username'}
              onSelect={user => {
                this.state.project.contributors.push(user);
              }}
            />
          </FormColumn>
          <FormColumn title="Edit Project Expenditure">
            <Input
              type={'text'}
              value={this.state.project.budget}
              placeholder={'Budget'}
              onChange={value => {
                this.setState({ project: { ...this.state.project, budget: value } });
              }}
            />
            <Input
              type={'text'}
              title={'Sale Amount'}
              value={this.state.project.sale_amount}
              placeholder={this.state.project.sale_amount}
              onChange={value => {
                this.setState({ project: { ...this.state.project, sale_amount: value } });
              }}
            />
            <Select
              placeholder={'Payment Type'}
              value={this.state.project.payment_type}
              onChange={value => {
                this.setState({ project: { ...this.state.project, payment_type: value } });
              }}
              options={['HOURLY', 'WEEKLY', 'FIXED_BID']}
            />
            <Typeahead
              endpoint={'contact'}
              value={this.state.project.accounting_contact}
              placeholder={'Accounting Contact'}
              labelKey={'name'}
              onSelect={contact => {
                this.setState({ project: { ...this.state.project, accounting_contact: contact.id } });
              }}
            />
          </FormColumn>
          <div className="submit-button">
            <Button className="project-submit" onClick={this.onClick.bind(this)} text={'Submit'} />
          </div>
        </Form>
      </div>
    );
  }
}
export default withRouter(
  connect(state => {
    return state;
  })(ProjectEditForm)
);
