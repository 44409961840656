import React from 'react';
import './Popup.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { listTask, createTask } from '../../../../../../modules/task';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Form from './../../../../../Form/Form';
import Input from './../../../../../Input/Input';
import Select from './../../../../../Select/Select';
import Button from '../../../../../Button/Button';

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      task: {
        name: this.props.name,
        task_category: this.props.type,
        description: '',
        project: this.props.object,
      },
      options: [this.props.type, 'BLOCKED', 'BACKLOG', 'IN_PROGRESS', 'COMPLETED'],
    };
  }

  componentDidMount() {
    this.props.dispatch(listTask());
  }

  async onTaskSubmit() {
    try {
      this.props.dispatch(createTask(this.state.task));
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="popup">
        <div className="popup_inner">
          <div className="close-popup-container">
            <button className="close-popup" onClick={this.props.closePopup}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <div className="task-form">
            <div className="form-header">
              <h4>{'Create Task'}</h4>
            </div>
            <Form className="task">
              <Input
                type={'text'}
                placeholder={'Name'}
                value={this.state.task.name}
                onChange={value => {
                  this.setState({ task: { ...this.state.task, name: value } });
                }}
              />
              <Input
                type={'text'}
                placeholder={'Description'}
                value={this.state.task.description}
                onChange={value => {
                  this.setState({ task: { ...this.state.task, description: value } });
                }}
              />
              <Select
                placeholder={'Category'}
                value={this.state.task.task_category}
                onChange={value => {
                  this.setState({ task: { ...this.state.task, task_category: value } });
                }}
                options={[...new Set(this.state.options)]}
              />

              <div className="submit-button">
                <Button className="task-submit" onClick={() => this.onTaskSubmit()} text={'Submit'} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(state => {
    return state;
  })(Popup)
);
