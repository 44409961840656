import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import * as hist from 'history';
import createRootReducer from './modules';

export const history = hist.createBrowserHistory();

export default function configureStore(preloadedState) {
  const initialState = {};
  const enhancers = [];
  const middleware = [thunk, routerMiddleware(history)];

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const store = createStore(createRootReducer(history), initialState, composedEnhancers);
  return store;
}
